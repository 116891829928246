import { TOKEN_QUERY_PARAM } from './InvitationTokenHelper';

const SITE_SCANNER_LOGIN_URL = process.env.SITE_SCANNER_URL;
export const getHomepagePath = (): string => {
	return '/';
};

export const getFlowAnalyzerForMobilePath = (): string => {
	return '/mobile-flow-analyzer';
};

export const getMobileSDKPath = (): string => {
	return '/mobile-sdk';
};

export const getWebSDKPath = (): string => {
	return '/web-sdk';
};

export const getLoginPath = (): string => {
	return '/login';
};

export const getLoginSuccessPath = (): string => {
	return '/login-success';
};

export const getSignupPath = (): string => {
	return '/signup';
};

export const getSiteScannerPath = (): string => {
	return SITE_SCANNER_LOGIN_URL;
};

export const getInvitationActivationPage = (invitationToken?: string): string => {
	return `/invitation-activation${
		invitationToken ? `?${TOKEN_QUERY_PARAM}=${invitationToken}` : ''
	}`;
};
export const getNoTenantPath = (): string => {
	return '/no-tenant';
};

export const getInvitationRedirectionPath = (): string => {
	return '/connect';
};

export const getInvitationsPagePath = (): string => {
	return '/invitations';
};
export const getUserManagementPagePath = (): string => {
	return '/manage-users/pending-invitations';
};
export const getManageUserTabsViewPagePath = (): string => {
	return '/manage-users';
};

export const getActiveUsers = (): string => {
	return '/manage-users/active-users';
};

export const getEditUserPath = (id: string): string => {
	return `/manage-users/active-users/${id}`;
};

export const getOpenInvitationRedirectionPath = (): string => {
	return '/open-connect';
};

export const getTenantCreationPage = (): string => {
	return '/tenant-creation';
};

export const getUnauthorized403Page = (): string => {
	return '/unauthorized';
};
