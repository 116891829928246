/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { History } from 'history';

import { MAIN_TITLE } from 'src/consts/products-consts';

import { PrivateRoute } from './components/auth/PrivateRoute';
import { AuthenticationChecker } from './components/authentication-checker/AuthenticationChecker';
import { Header } from './components/header/Header';
import { isDeviceSupported } from './helpers/DeviceHelper';
import {
	getFlowAnalyzerForMobilePath,
	getHomepagePath,
	getInvitationActivationPage,
	getInvitationRedirectionPath,
	getInvitationsPagePath,
	getLoginPath,
	getLoginSuccessPath,
	getManageUserTabsViewPagePath,
	getMobileSDKPath,
	getNoTenantPath,
	getOpenInvitationRedirectionPath,
	getSignupPath,
	getTenantCreationPage,
	getUnauthorized403Page,
	getUserManagementPagePath,
	getWebSDKPath
} from './helpers/RoutesHelper';
import { AuthToken } from './pages/authentication/AuthToken';
import { LoginPage } from './pages/authentication/LoginPage';
import { SignupPage } from './pages/authentication/SignupPage';
import { InvitationActivationPage } from './pages/invitation-activation/InvitationActivationPage';
import { EmailInvitationRedirectionPage } from './pages/invitation-redirection/EmailInvitationRedirectionPage';
import { ManageUsersRouter } from './pages/manage-users-tabs-view/ManageUsersRouter';
import { ManageUsersTabsView } from './pages/manage-users-tabs-view/ManageUsersTabsView';
import { MobileFlowAnalyzerPage } from './pages/mobile-flow-analyzer-page/MobileFlowAnalyzerPage';
import { MobileSDKPage } from './pages/mobile-sdk-page/MobileSDKPage';
import { NoTenantPage } from './pages/no-tenant/NoTenantPage';
import { NotFound404Page } from './pages/not-found-404-page/NotFound404Page';
import { NotSupportedScreenSizePage } from './pages/not-supported-screen-size/NotSupportedScreenSizePage';
import { OpenInvitationRedirectionPage } from './pages/open-invitation-redirection/OpenInvitationRedirectionPage';
import { ProductActivationLoader } from './pages/product-activation-loader/ProductActivationLoader';
import { TenantCreationPage } from './pages/tenant-creation/TenantCreationPage';
import { Unauthorized403Page } from './pages/unauthorized-403-page/Unauthorized403Page';
import { WebSDKPage } from './pages/web-sdk-page/WebSdkPage';
import { WelcomePage } from './pages/welcome-page/WelcomePage';
import { ConfigurationProvider } from './providers/configurationProvider/ConfigurationProvider';
import { NavigationProvider } from './providers/navigationProvider/NavigationProvider';
import { NotificationsProvider } from './providers/notificationsProvider/NotificationsProvider';
import { TableStateProvider } from './providers/tableStateProvider/TableStateProvider';
import { UserTenantProvider } from './providers/userTenantProvider/UserTenantProvider';
import { ProductType } from './types/Tenant';

import './App.scss';

type IApp = {
	history: History;
};

export const App: FC<IApp> = ({ history }) => {
	if (!isDeviceSupported()) {
		return <NotSupportedScreenSizePage />;
	}
	const isAuthenticationRequired = (pathname: string): boolean => {
		const unauthenticatedRoutes = [getSignupPath(), getLoginPath(), getInvitationRedirectionPath()];
		const isUnauthenticatedPage = unauthenticatedRoutes.some((path) => {
			return pathname.startsWith(path);
		});
		return !isUnauthenticatedPage;
	};
	return (
		<UserTenantProvider>
			<ConfigurationProvider>
				<NotificationsProvider>
					<NavigationProvider>
						<TableStateProvider state={{}}>
							<HubspotProvider>
								<Router history={history}>
									<div className="app">
										<Route
											render={({ location }) => {
												return isAuthenticationRequired(location.pathname) ? (
													<AuthenticationChecker />
												) : null;
											}}
										/>
										<Route
											render={({ location }) => {
												// don't show the header on unauthenticated screens
												const isAuthRequired = isAuthenticationRequired(location.pathname);
												return isAuthRequired ? <Header isAuthenticatedPage={true} /> : null;
											}}
										/>

										<main className="main-section" aria-labelledby={MAIN_TITLE}>
											<Switch>
												<Route
													exact
													path={getInvitationRedirectionPath()}
													component={EmailInvitationRedirectionPage}
												/>
												<Route
													exact
													path={getOpenInvitationRedirectionPath()}
													component={OpenInvitationRedirectionPage}
												/>
												<Route exact path={getLoginPath()} component={LoginPage} />
												<Route exact path={getSignupPath()} component={SignupPage} />
												<Route exact path={getLoginSuccessPath()} component={AuthToken} />
												<PrivateRoute
													exact
													path={getHomepagePath()}
													component={() => <WelcomePage />}
												/>
												<PrivateRoute
													exact
													path={getFlowAnalyzerForMobilePath()}
													component={() => (
														<ProductActivationLoader productType={ProductType.MOBILE_FLOW_ANALYZER}>
															<MobileFlowAnalyzerPage />
														</ProductActivationLoader>
													)}
												/>
												<PrivateRoute
													exact
													path={getMobileSDKPath()}
													component={() => (
														<ProductActivationLoader productType={ProductType.MOBILE_SDK}>
															<MobileSDKPage />
														</ProductActivationLoader>
													)}
												/>
												<PrivateRoute
													exact
													path={getWebSDKPath()}
													component={() => (
														<ProductActivationLoader productType={ProductType.WEB_SDK}>
															<WebSDKPage />
														</ProductActivationLoader>
													)}
												/>

												<Route
													exact
													path={getInvitationsPagePath()}
													component={() => (
														<Redirect
															to={{
																pathname: getUserManagementPagePath()
															}}
														/>
													)}
												/>
												<PrivateRoute
													exact
													path={getUserManagementPagePath()}
													component={ManageUsersTabsView}
												/>
												<PrivateRoute
													exact
													path={getInvitationActivationPage()}
													component={InvitationActivationPage}
												/>
												<PrivateRoute
													path={getManageUserTabsViewPagePath()}
													component={ManageUsersRouter}
												/>
												<PrivateRoute
													exact
													path={getTenantCreationPage()}
													component={TenantCreationPage}
												/>
												<PrivateRoute exact path={getNoTenantPath()} component={NoTenantPage} />
												<PrivateRoute
													exact
													path={getUnauthorized403Page()}
													component={Unauthorized403Page}
												/>
												<PrivateRoute component={NotFound404Page} />
											</Switch>
										</main>
									</div>
								</Router>
							</HubspotProvider>
						</TableStateProvider>
					</NavigationProvider>
				</NotificationsProvider>
			</ConfigurationProvider>
		</UserTenantProvider>
	);
};
