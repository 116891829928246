import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { EvNoTenant } from '@evinced-private/ui-common';

import { getHomepagePath, getSignupPath } from '../../helpers/RoutesHelper';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import { getUserEmail } from '../../services/AuthenticationService';

export const NoTenantPage: FC = () => {
	const { tenant } = useUserTenant();
	const history = useHistory();
	if (tenant) {
		history.push(getHomepagePath());
		return null;
	}
	return (
		<EvNoTenant
			userEmail={getUserEmail()}
			portalId={process.env.HUBSPOT_API_KEY}
			onSignUpWithEmail={() => history.push(getSignupPath())}
		/>
	);
};
