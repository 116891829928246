import { ProductType, TenantProduct } from 'src/types/Tenant';

import { Logger } from '../Logger';
import { API_URL_PATHS } from '../server/ApiUrlPaths';
import { apiCall } from '../server/ServerApi';

const { CENTRAL_CONFIG_PATH } = API_URL_PATHS;
export type TConfiguration = Record<string, Record<string, unknown>>;

// product hub config lives under the platform product
const getPLatformProductID = (products: TenantProduct[]): string => {
	if (!products) {
		return null;
	}
	return products.find((product: TenantProduct) => product.type === ProductType.PLATFORM)?.id;
};

export const getCentralConfig = async (products: TenantProduct[]): Promise<TConfiguration> => {
	const platformProductID = getPLatformProductID(products);
	if (platformProductID) {
		try {
			const configurationResult: { PRODUCT_HUB_UI: TConfiguration } = await apiCall(
				'GET',
				`${CENTRAL_CONFIG_PATH}?productId=${platformProductID}&subPath=PRODUCT_HUB_UI`,
				{ isConfigUrl: true }
			);

			return configurationResult?.PRODUCT_HUB_UI || {};
		} catch (error) {
			Logger.error('Error fetching central configuration', error);
			return null;
		}
	}
	return null;
};
