import React from 'react';
import ReactDOM from 'react-dom';

import { InitSentry } from 'src/services/SentryService';

import { initAnalytics } from './services/analytics/AnalyticsService';
import { initHubspotClient } from './services/analytics/HubspotService';
import { AppContainer } from './AppContainer';

import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

// Init analytics
initAnalytics();

// Init hubspot
initHubspotClient();

// Init Sentry
InitSentry();

// eslint-disable-next-line no-undef
console.log(`Current UI Version is: ${UI_VERSION}`);

const rootElement = document.getElementById('root');
if (!rootElement) {
	throw new Error('Root element not found');
}

ReactDOM.render(<AppContainer />, rootElement);
